import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  total: 0,
  storeDetail: null,
  store: [],
  storeMapById: {},
  storeMapByName: {},
};
const storeSlice = createSlice({
  name: 'store_management',
  initialState: initialState,
  reducers: {
    updateData: (state, action) => {
      state.data = action.payload.stores;
      state.total = action.payload.total;
    },
    addNewData: (state, action) => {
      const tmpData = [...state.data];
      tmpData.push(action.payload);
      state.data = tmpData;

      let total = state.total + 1;
      state.total = total;
    },
    deleteData: (state, action) => {
      const storeId = action.payload;
      const ids = state.data.map((item) => item._id);
      const idx = ids.indexOf(storeId);
      if (idx >= 0) {
        const tmpData = [...state.data];
        tmpData.splice(idx, 1);
        state.data = tmpData;

        const total = state.total - 1;
        state.total = total;
      }
    },
    updateStore: (state, action) => {
      const { storeId, payload } = action.payload;
      const ids = state.data.map((item) => item.id);
      const idx = ids.indexOf(storeId);
      if (idx >= 0) {
        const tmpData = [...state.data];
        tmpData[idx] = { ...tmpData[idx], ...payload };
        state.data = tmpData;
      }
    },
    setStoreDetail: (state, action) => {
      state.storeDetail = action.payload;
    },
    setStores: (state, action) => {
      state.store = action.payload;
    },
    setStoreMapById: (state, action) => {
      state.storeMapById = action.payload;
    },
    setStoreMapByName: (state, action) => {
      state.storeMapByName = action.payload;
    },
  },
});

export const storeAction = storeSlice.actions;

export default storeSlice.reducer;
