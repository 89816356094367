// extracted by css-extract-rspack-plugin
var _1 = "YUawYaZh1uyV3_9US9FE";
var _2 = "pJzcB2z5x0HPFcXVpHaE";
var _3 = "IpC4tf3zrFYvAfuGGBSP";
var _4 = "J_P_5ecIAv2sQR6PKbls";
var _5 = "QbGxFqzquVDO80zQcHxt";
var _6 = "auhhzMCNxVoyPPiIUDuw";
var _7 = "n3bkn0OazFPrgGm4qW6c";
var _8 = "pkKpaqY9Bl2kTg__bddR";
var _9 = "m4LHnoLhLyEGUHZ74C4y";
export { _1 as "container", _2 as "extension_container", _3 as "extension_header", _4 as "extension_item", _5 as "extension_main", _6 as "icon_1", _7 as "icon_2", _8 as "text", _9 as "text_2" }
