// extracted by css-extract-rspack-plugin
var _1 = "P_Sj9W0ShMAfR_BaIXvg";
var _2 = "_xph7HIC2GfYeOtslHNg";
var _3 = "dD08M207OG88OK9YHCvj";
var _4 = "VwMhRetT6IfV81cvZWuv";
var _5 = "z9e425BATb4Ovh3trS_A";
var _6 = "pLwupEjkOqR5hhVtKa7C";
var _7 = "RgMpOp7gndCFNYQuz6dw";
var _8 = "s85yebbAiXNOxn8tV9xb";
export { _1 as "container", _2 as "logo", _3 as "menu_container", _4 as "menu_item_container", _5 as "menu_scroll_button_left", _6 as "menu_scroll_button_right", _7 as "text_menu", _8 as "wrapper" }
