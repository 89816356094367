import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import NamiTechLogo from '@public/images/logo.webp';
import { Button } from 'antd';
import { useAuthProvider } from 'contexts/authenticationContext';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { navMenu } from '../constants';
import Avatar from './Avatar.jsx';
import * as styles from './Header.module.css';

function resizeImage(src, width, height, callback) {
  const img = new Image();
  img.src = src;

  img.onload = function () {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(img, 0, 0, width, height);

    // Get the resized image as a DataURL
    callback(canvas.toDataURL('image/webp'));
  };
}

function Header() {
  const { currentUser } = useAuthProvider();
  const location = useLocation();

  const [resizedLogo, setResizedLogo] = useState(null);

  useEffect(() => {
    resizeImage(NamiTechLogo, 25, 25, (resized) => {
      setResizedLogo(resized);
    });
  }, []);

  const handleMenuScrollLeft = () => {
    const menuContainer = document.querySelector('#menu_container');
    menuContainer.scrollLeft -= menuContainer.clientWidth / 3; // @NOTE:Adjust scroll amount as needed
  };

  const handleMenuScrollRight = () => {
    const menuContainer = document.querySelector('#menu_container');
    menuContainer.scrollLeft += menuContainer.clientWidth / 3; // @NOTE: Adjust scroll amount as needed
  };

  const renderMenuItems = (menu) =>
    menu.map((item) => {
      const isMatch = location.pathname?.includes(item.path);
      return (
        <div
          className={styles.menu_item_container}
          key={`${item.path}_menu_item`}
          style={{
            background: isMatch ? '#1677ff' : null,
          }}
        >
          <Link
            key={`${item.path}_link`}
            to={item.path}
            className={styles.text_menu}
          >
            {item.label}
          </Link>
        </div>
      );
    });

  return (
    <div className={styles.container}>
      {resizedLogo && (
        <img
          src={resizedLogo}
          className={styles.logo}
          alt="NamiTech Logo"
          width={25}
          height={25}
        />
      )}
      <>
        {currentUser && (
          <div className={styles.wrapper}>
            <Button
              type="ghost"
              shape="circle"
              icon={<LeftCircleOutlined />}
              onClick={handleMenuScrollLeft}
              className={styles.menu_scroll_button_left}
            />
            <div id="menu_container" className={styles.menu_container}>
              {renderMenuItems(navMenu[currentUser.role])}
            </div>
            <Button
              id="menu_scroll_button_right"
              type="ghost"
              shape="circle"
              icon={<RightCircleOutlined />}
              onClick={handleMenuScrollRight}
              className={styles.menu_scroll_button_right}
            />
          </div>
        )}
        <Avatar />
      </>
    </div>
  );
}

export default Header;
