import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setAnalysisData: (state, action) => {
      state.data = action.payload;
    },
    clearAnalysisData: (state) => {
      state.data = null;
    },
  },
});

export const { setAnalysisData, clearAnalysisData } = analysisSlice.actions;

export default analysisSlice.reducer;
